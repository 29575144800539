import React from "react";

export const Clock: React.FC = () => (
  <svg width="200" height="200" viewBox="0 0 100 100" xmlns="http://www.w3.org/2000/svg">
    <circle cx="50" cy="50" r="45" stroke="#C7ACEF" strokeWidth="1" fill="white" />
    <line x1="50" y1="50" x2="50" y2="30" stroke="#5E0B69" strokeWidth="1" strokeLinecap="round">
      <animateTransform
        attributeName="transform"
        attributeType="XML"
        type="rotate"
        from="0 50 50"
        to="360 50 50"
        dur="42s"
        repeatCount="indefinite"
      />
    </line>
    <line x1="50" y1="50" x2="50" y2="15" stroke="#5E0B69" strokeWidth="1" strokeLinecap="round">
      <animateTransform
        attributeName="transform"
        attributeType="XML"
        type="rotate"
        from="0 50 50"
        to="360 50 50"
        dur="3.5s"
        repeatCount="indefinite"
      />
    </line>
  </svg>
);
